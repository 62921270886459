<!-- eslint-disable no-console -->
<script>
import Layout from "../../../layouts/main";
import PageHeader from "@/components/page-header-v2";
import appConfig from "@/app.config";
import api from "../../../../common/axios";
import PDFViewer from "@/components/pdfViewer";

/**
 * Blank page component
 */
export default {
  page: {
    title: "Lihat Truck Owner",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: { Layout, PageHeader, PDFViewer },
  data() {
    return {
      config: {
        redirect: "truck_owner",
        api: "truck_owner",
        update_account: "truck_owner/update_account_status",
        driver_by_owner:
          "driver?orderby=id&sort=desc&account_status=&document_status&owner=truck_owner&owner_id=",
        vehicle_by_owner:
          "vehicle?orderby=id&sort=desc&page=1&per_page=10&key&owner=truck_owner&owner_id=",
        account_bank:
          "bank_account?owner=truck_owner&orderby=id&sort=desc&owner_id=",
        vehicle: "vehicle/",
        update_account_driver: "driver/update_account_status",
        update_status_vehicle: "vehicle/update_status/",
      },
      tableData1: [],
      fields1: [
        { key: "ktp.ktp_no", label: "No KTP" },
        { key: "sim.sim_no", label: "No SIM" },
        { key: "name", label: "Name" },
        { key: "phone", label: "Telepon" },
        { key: "address", label: "Alamat" },
        { key: "driver_status", label: "Status" },
        { key: "action" },
      ],

      title_modal: null,
      file_path_modal: null,

      title_modal_armada: null,
      file_path_modal_armada: null,

      tableData2: [],
      fields2: [
        { key: "stnk_number", label: "No STNK" },
        { key: "plat_number", label: "No Plat" },
        { key: "vehicle_category.name", label: "Tipe" },
        { key: "merk", label: "Merk" },
        { key: "color", label: "Warna" },
        { key: "status_vehilce", label: "Status" },
        { key: "action" },
      ],

      tableData3: [],
      fields3: [
        { key: "bank_name", label: "Nama" },
        { key: "account_number", label: "Rekening" },
        { key: "account_name", label: "Atas Nama" },
      ],
      title: "Lihat Truck Owner",
      items: [
        {
          text: "Truck Owner",
          href: "/truck-owner",
        },
        {
          text: "Lihat Detail",
          href: "/truck-owner",
          active: true,
        },
      ],
      model: {
        code: "",
      },
      driver: {},
      badge: "",
      driver_modal_data: {
        ktp: {},
        sim: {},
      },
      armada_modal_data: {},
      reject_reason: null,
      reject_reason_driver: null,
    };
  },

  methods: {
    hideModal() {
      this.$refs["driver-modal"].hide();
      this.$refs["armada-modal"].hide();
      this.driver_modal_data = {
        ktp: {},
      };
    },
    getData() {
      let _ = this;
      api.get(_.config.api + "/" + this.$route.params.id).then((response) => {
        _.model = response.data.data;
        this.badge = _.model.code;
        _.getDiver();
        _.getVehicle();
        _.getAccountBank();
      });
    },

    show_modal(types, data) {
      this.$refs["show-modal"].show();
      let _ = this;
      if (types == 1) {
        _.title_modal = "KTP";
        _.file_path_modal = _.driver_modal_data.ktp.ktp_path;
      } else if (types == 2) {
        _.title_modal = "Selfie KTP";
        _.file_path_modal = _.driver_modal_data.ktp.selfie_ktp_path;
      } else if (types == 3) {
        _.title_modal = "SIM";
        _.file_path_modal = _.driver_modal_data.sim.sim_path;
      } else if (types == 4) {
        _.title_modal = "Selfie SIM";
        _.file_path_modal = _.driver_modal_data.sim.selfie_sim_path;
      } else {
        _.title_modal = "Vaccine";
        _.file_path_modal = data;
      }
    },

    show_modal_armada(types) {
      this.$refs["show-modal_armada"].show();
      let _ = this;
      if (types == 1) {
        _.title_modal_armada = "STNK";
        _.file_path_modal_armada = _.armada_modal_data.stnk_path;
      } else if (types == 2) {
        _.title_modal_armada = "KIR";
        _.file_path_modal_armada = _.armada_modal_data.kir_path;
      }
    },

    getDiver() {
      let _ = this;
      api
        .get(_.config.driver_by_owner + this.$route.params.id)
        .then((response) => {
          _.tableData1 = response.data.data;
        });
    },

    getAccountBank() {
      let _ = this;
      api
        .get(_.config.account_bank + this.$route.params.id)
        .then((response) => {
          _.tableData3 = response.data.data;
        });
    },

    getVehicle() {
      let _ = this;
      api
        .get(_.config.vehicle_by_owner + this.$route.params.id)
        .then((response) => {
          _.tableData2 = response.data.data;
        });
    },
    show_ktp() {
      this.$refs["ktp-modal"].show();
    },
    show_kk() {
      this.$refs["kk-modal"].show();
    },

    show_ktp_selfie() {
      this.$refs["selfie-modal"].show();
    },

    show_driver(data) {
      this.driver_modal_data = data;
      // console.log(this.driver_modal_data.id);
      this.$refs["driver-modal"].show();
    },

    async show_armada(id) {
      let _ = this;
      await api.get(_.config.vehicle + id).then((response) => {
        _.armada_modal_data = response.data.data;
        // _.armada_modal_data.photos = JSON.parse(_.armada_modal_data.photos);
      });
      this.$refs["armada-modal"].show();
    },

    changeStatus(status) {
      let _ = this;
      if (status != "ditolak") {
        let data = { account_status: status };
        api
          .put(_.config.update_account + "/" + this.$route.params.id, data)
          .then(() => {
            _.$toast("Status account sukses diubah", {
              icon: "fas fa-check",
              type: "success",
            });
            _.getData();
          });
      } else {
        this.$refs["show-modal-reject"].show();
      }
    },

    changeReject() {
      let _ = this;
      let data = {
        account_status: "ditolak",
        reject_reason: this.reject_reason,
      };
      api
        .put(_.config.update_account + "/" + this.$route.params.id, data)
        .then(() => {
          _.$toast("Status Account sukses diubah", {
            icon: "fas fa-check",
            type: "success",
          });
          this.$refs["show-modal-reject"].hide();
          _.getData();
        });
    },

    changeStatusDriver(status, id) {
      console.log(status, id);
      let _ = this;
      if (status != "ditolak") {
        let data = { account_status: status };
        api.put(_.config.update_account_driver + "/" + id, data).then(() => {
          _.$toast("Status account Driver sukses diubah", {
            icon: "fas fa-check",
            type: "success",
          });
          _.getData();
          this.$refs["driver-modal"].hide();
        });
      } else {
        // this.$refs["show-modal-reject-driver"].show();
        this.changeRejectDriver(id);
      }
    },

    changeRejectDriver(id) {
      let _ = this;
      let data = {
        account_status: "ditolak",
        // reject_reason: this.reject_reason_driver,
        reject_reason: "-",
      };
      api.put(_.config.update_account_driver + "/" + id, data).then(() => {
        _.$toast("Status Account sukses diubah", {
          icon: "fas fa-check",
          type: "success",
        });
        this.$refs["driver-modal"].hide();
        this.$refs["show-modal-reject-driver"].hide();
        _.getData();
      });
    },

    changeStatusArmada(status, id) {
      let _ = this;
      let data = { status: status };
      api.put(_.config.update_status_vehicle + "/" + id, data).then(() => {
        _.$toast("Status account Armada sukses diubah", {
          icon: "fas fa-check",
          type: "success",
        });
        _.getData();
        this.$refs["armada-modal"].hide();
      });
    },
  },

  beforeMount() {
    this.getData();
  },
  $route(newVal, oldVal) {
    // TODO: react to navigation event.
    // params cotains the current route parameters
    if (newVal != oldVal) {
      let _ = this;
      _.getData();
    }
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" :badge="badge" />
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <div class="row">
              <div
                class="col-md-3"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <h6 class="text-warning text-capitalize">
                  {{ model.account_status }} - {{ model.document_status }}
                </h6>
                <h5>{{ model.name }}</h5>
                <h6>{{ model.address }}</h6>
                <h6>{{ model.email }}</h6>
              </div>

              <div
                class="col-md-3"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <div class="ml-3">
                  <label class="mb-0 form-label font-weight-light"
                    >Alamat Pool</label
                  >
                  <h6>{{ model.pool_address }}</h6>
                  <label class="mb-0 form-label font-weight-light"
                    >Telepon</label
                  >
                  <h6>{{ model.phone }}</h6>
                </div>
              </div>

              <div
                class="col-md-3"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <div class="ml-4">
                  <label class="mb-0 form-label font-weight-light"
                    >Tanggal Registrasi</label
                  >
                  <h6>{{ model.registration_at | moment("YYYY MMMM DD") }}</h6>
                  <label class="mb-0 form-label font-weight-light"
                    >Aktifitas Terakhir</label
                  >
                  <h6 v-if="model.last_activity_at != null">
                    {{ model.last_activity_at | moment("YYYY MMMM DD") }}
                  </h6>
                  <h6 v-else>-</h6>
                </div>
              </div>

              <div class="col-md-3">
                <div class="ml-4">
                  <label class="mb-0 form-label font-weight-light"
                    >Total Pendapatan</label
                  >
                  <h6>{{ model.income | currency }}</h6>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <h4>Dokumen Truck Owner</h4>
                <div class="row">
                  <div class="col-md-8">
                    <div class="row">
                      <div
                        class="col-md-3 text-center p-2 ml-2"
                        style="border: 2px solid #333; border-radius: 2px"
                      >
                        <h6>KTP</h6>
                        <h6>{{ model.ktp_number }}</h6>
                        <h6 class="manual-link">
                          <a @click="show_ktp">Lihat</a>
                        </h6>
                      </div>

                      <div
                        class="col-md-3 text-center p-2 ml-2"
                        style="border: 2px solid #333; border-radius: 2px"
                      >
                        <h6>Selfie KTP</h6>
                        <h6>{{ model.ktp_number }}</h6>
                        <h6 class="manual-link">
                          <a @click="show_ktp_selfie">Lihat</a>
                        </h6>
                      </div>

                      <div
                        class="col-md-3 text-center p-2 ml-2"
                        style="border: 2px solid #333; border-radius: 2px"
                      >
                        <label>Kartu Keluarga</label>
                        <h6>{{ model.kk_number }}</h6>
                        <h6 class="manual-link">
                          <a @click="show_kk">Lihat</a>
                        </h6>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <div class="row">
                  <div class="col-md-12">
                    <div class="card">
                      <b-tabs pills class="navtab-bg mt-2" content-class="p-3">
                        <b-tab active>
                          <template v-slot:title>
                            <h6 class="d-none d-sm-inline-block">
                              Driver ({{ tableData1.total_data }})
                            </h6>
                          </template>
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="tableData1.rows"
                              :fields="fields1"
                              show-empty
                              responsive="sm"
                              striped
                            >
                              <template #cell(driver_status)="data">
                                <span class="text-capitalize">
                                  {{ data.item.account_status }}
                                </span>
                              </template>
                              <template #cell(action)="data">
                                <div style="min-width: 80px">
                                  <b-button
                                    v-b-tooltip.hover.top="'Lihat Detail'"
                                    variant="primary"
                                    size="sm"
                                    class="mb-0"
                                    @click="show_driver(data.item)"
                                  >
                                    <i class="ti-eye"></i>
                                  </b-button>
                                </div>
                              </template>
                            </b-table>
                          </div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <h6 class="d-none d-sm-inline-block">
                              Armada ({{ tableData2.total_data }})
                            </h6>
                          </template>
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="tableData2.rows"
                              show-empty
                              responsive="sm"
                              :fields="fields2"
                              striped
                            >
                              <template #cell(status_vehilce)="data">
                                <span class="text-capitalize">
                                  {{ data.item.status }}
                                </span>
                              </template>
                              <template #cell(action)="data">
                                <div style="min-width: 80px">
                                  <b-button
                                    v-b-tooltip.hover.top="'Lihat Detail'"
                                    variant="primary"
                                    size="sm"
                                    @click="show_armada(data.item.id)"
                                  >
                                    <i class="ti-eye"></i>
                                  </b-button>
                                </div>
                              </template>
                            </b-table>
                          </div>
                        </b-tab>
                        <b-tab>
                          <template v-slot:title>
                            <h6 class="d-none d-sm-inline-block">
                              Akun Bank ({{ tableData3.total_data }})
                            </h6>
                          </template>
                          <div class="table-responsive mb-0">
                            <b-table
                              :items="tableData3.rows"
                              :fields="fields3"
                              show-empty
                              striped
                              responsive="sm"
                            >
                            </b-table>
                          </div>
                        </b-tab>
                      </b-tabs>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-4">
                <b-button variant="light" to="/truck-owner">Kembali</b-button>
                <b-button
                  class="ml-3"
                  variant="primary"
                  @click="changeStatus('aktif')"
                  >Setujui</b-button
                >
                <b-button
                  class="ml-3"
                  variant="outline-danger"
                  @click="changeStatus('ditolak')"
                  >Tolak</b-button
                >
                <b-button
                  class="ml-3"
                  variant="outline-danger"
                  @click="changeStatus('suspend')"
                  >Suspend</b-button
                >
                <b-button
                  class="ml-3"
                  variant="primary"
                  @click="changeStatus('aktif')"
                  >Aktifkan</b-button
                >
              </div>
            </div>
          </div>
        </div>
        <b-modal
          id="modal-center"
          centered
          size="lg"
          ref="ktp-modal"
          title="KTP"
          title-class="font-18"
          hide-footer
        >
          <div class="modal-body min-height-modal">
            <!-- <embed :src="model.ktp_path" class="embed-classes" />

            
            <iframe :src="model.ktp_path" width="100%" height="500px" /> -->
            <PDFViewer :file="model.ktp_path" />
          </div>
        </b-modal>

        <b-modal
          id="modal-center"
          centered
          ref="kk-modal"
          title="KK"
          size="lg"
          title-class="font-18"
          hide-footer
        >
          <div class="modal-body min-height-modal">
            <PDFViewer :file="model.kk_path" />
            <!-- <embed :src="model.kk_path" class="embed-classes" /> -->
          </div>
        </b-modal>

        <b-modal
          id="modal-center"
          centered
          ref="selfie-modal"
          title="Selfie KTP"
          size="lg"
          title-class="font-18"
          hide-footer
        >
          <div class="modal-body min-height-modal">
            <PDFViewer :file="model.selfie_ktp" />
            <!-- <embed :src="model.kk_path" class="embed-classes" /> -->
          </div>
        </b-modal>

        <!-- Driver Modal -->
        <b-modal
          id="modal-center"
          centered
          ref="driver-modal"
          size="xl"
          no-close-on-backdrop
          hide-footer
        >
          <template #modal-header>
            <div class="col-md-12">
              <span class="font-size-22 mt-2 mr-3"
                ><strong> Lihat Driver </strong></span
              >
              <b-badge pill variant="primary" class="pl-4 pt-2 pr-4"
                ><h6>
                  {{ driver_modal_data.name + " - " + driver_modal_data.phone }}
                </h6></b-badge
              >
              <span class="text-orange mt-5 text-capitalize">
                &nbsp;{{ driver_modal_data.account_status }}
              </span>
            </div>
          </template>
          <div class="modal-body">
            <div class="row mb-0 mt-0">
              <div class="col-md-4">
                <span class="font-size-16"><strong> KTP </strong></span>
              </div>

              <div class="col-md-4">
                <span class="font-size-16"><strong> SIM </strong></span>
              </div>

              <div class="col-md-4">
                <span class="font-size-16"><strong> Vaksin </strong></span>
              </div>

              <div
                class="col-md-4"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <label class="mb-0 mt-0 form-label font-weight-light">No</label>
                <h6>{{ driver_modal_data.ktp.ktp_no }}</h6>

                <label class="mb-0 mt-0 form-label font-weight-light"
                  >Nama</label
                >
                <h6>{{ driver_modal_data.ktp.name }}</h6>

                <label class="mb-0 mt-0 form-label font-weight-light"
                  >Jenis Kelamin</label
                >
                <h6>
                  {{
                    driver_modal_data.ktp.gender == "L"
                      ? "Laki - Laki"
                      : "Perempuan"
                  }}
                </h6>

                <label class="mb-0 mt-0 form-label font-weight-light"
                  >Tanggal Lahir</label
                >
                <h6>
                  {{ driver_modal_data.dob | moment("DD MMMM YYYYY") }}
                </h6>

                <label class="mb-0 mt-0 form-label font-weight-light"
                  >Alamat</label
                >
                <h6>{{ driver_modal_data.ktp.address }}</h6>

                <div class="row">
                  <div
                    class="col-md-4 ml-2 mt-2 text-center"
                    style="border: 2px solid #333333; border-radius: 1px"
                  >
                    <h6 class="text-center">KTP</h6>
                    <h6 class="manual-link">
                      <a @click="show_modal(1)">Lihat</a>
                    </h6>
                  </div>

                  <div
                    class="col-md-4 ml-2 mt-2 text-center"
                    style="border: 2px solid #333333; border-radius: 1px"
                  >
                    <h6 class="text-center">Selfie KTP</h6>
                    <h6 class="manual-link">
                      <a @click="show_modal(2)">Lihat</a>
                    </h6>
                  </div>
                </div>
              </div>

              <div
                class="col-md-4"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <label class="mb-0 mt-0 form-label font-weight-light">No</label>
                <h6>
                  {{
                    (driver_modal_data.sim && driver_modal_data.sim.sim_no) ||
                    "-"
                  }}
                </h6>

                <label class="mb-0 mt-0 form-label font-weight-light"
                  >Nama</label
                >
                <h6>
                  {{ driver_modal_data.sim ? driver_modal_data.sim.name : "-" }}
                </h6>

                <label class="mb-0 mt-0 form-label font-weight-light"
                  >Tipe SIM</label
                >
                <h6>
                  SIM
                  {{ driver_modal_data.sim ? driver_modal_data.sim.type : "-" }}
                </h6>

                <label class="mb-0 mt-0 form-label font-weight-light"
                  >Masa Berlaku</label
                >
                <h6 v-if="driver_modal_data.sim">
                  {{
                    driver_modal_data.sim.expired_at | moment("DD MMMM YYYY")
                  }}
                </h6>
                <h6 v-else>-</h6>

                <div class="row">
                  <div
                    class="col-md-4 ml-2 mt-2 text-center"
                    style="border: 2px solid #333333; border-radius: 1px"
                  >
                    <h6 class="text-center">SIM</h6>
                    <h6 class="manual-link">
                      <a @click="show_modal(3)">Lihat</a>
                    </h6>
                  </div>

                  <div
                    class="col-md-4 ml-2 mt-2 text-center"
                    style="border: 2px solid #333333; border-radius: 1px"
                  >
                    <h6 class="text-center">Selfie SIM</h6>
                    <h6 class="manual-link">
                      <a @click="show_modal(4)">Lihat</a>
                    </h6>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="row">
                  <div
                    class="col-md-9 ml-2 mb-2 p-3"
                    style="border: 2px solid #333333; border-radius: 1px"
                    v-for="(i, index) in driver_modal_data.vaccines"
                    :key="index"
                  >
                    <div class="row">
                      <div class="col-md-6">
                        <label class="mb-0 mt-0 form-label font-weight-light"
                          >Sertifikat</label
                        >
                        <h6>{{ i.vaccine_no }}</h6>
                      </div>
                      <div class="col-md-6 text-right">
                        <label class="mb-0 mt-0 form-label font-weight-light"
                          >Dosis</label
                        >
                        <h6>{{ index + 1 }}</h6>
                      </div>

                      <div class="col-md-12 manual-link">
                        <a @click="show_modal(4, i.vaccine_path)">Lihat</a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-12 mt-4 mb-0">
                <b-button variant="light" @click="hideModal"> Tutup </b-button>
                <b-button
                  variant="primary"
                  class="ml-3"
                  @click="changeStatusDriver('aktif', driver_modal_data.id)"
                  >Setujui</b-button
                >
                <b-button
                  class="ml-3"
                  variant="outline-danger"
                  @click="changeStatusDriver('ditolak', driver_modal_data.id)"
                  >Tolak</b-button
                >
                <!-- <b-button
                  class="ml-3"
                  variant="warning"
                  @click="changeStatusDriver('suspend', driver_modal_data.id)"
                  >Suspend</b-button
                > -->
                <b-button
                  class="ml-3"
                  variant="primary"
                  @click="changeStatusDriver('aktif', driver_modal_data.id)"
                  >Aktifkan</b-button
                >

                <!-- <b-button class="ml-3" variant="warning">Hapus</b-button> -->
              </div>
            </div>
          </div>
        </b-modal>

        <!--  Armada  -->
        <b-modal
          id="modal-center"
          centered
          ref="armada-modal"
          size="xl"
          no-close-on-backdrop
          hide-footer
        >
          <template #modal-header>
            <div class="col-md-12">
              <span class="font-size-22 mt-2 mr-3"
                ><strong> Lihat Armada </strong></span
              >
              <b-badge pill variant="primary" class="pl-4 pt-2 pr-4"
                ><h6>
                  {{ armada_modal_data.stnk_number }}
                </h6></b-badge
              >
              <span class="text-orange mt-5 text-capitalize">
                &nbsp;{{ armada_modal_data.status }}
              </span>
            </div>
          </template>
          <div class="modal-body">
            <div class="row mb-0 mt-0">
              <div class="col-md-4">
                <span class="font-size-16"><strong> STNK </strong></span>
              </div>

              <div class="col-md-4">
                <span class="font-size-16"><strong> KIR </strong></span>
              </div>

              <div class="col-md-4">
                <span class="font-size-16"><strong> Foto </strong></span>
              </div>

              <div
                class="col-md-4"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >No</label
                    >
                    <h6>{{ armada_modal_data.stnk_number }}</h6>
                  </div>

                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >No Plat</label
                    >
                    <h6>{{ armada_modal_data.plat_number }}</h6>
                  </div>

                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Merk</label
                    >
                    <h6>{{ armada_modal_data.merk }}</h6>
                  </div>

                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Kategori Armada</label
                    >
                    <h6>
                      {{
                        (armada_modal_data.vehicle_category &&
                          armada_modal_data.vehicle_category.name) ||
                        "-"
                      }}
                    </h6>
                  </div>

                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Warna</label
                    >
                    <h6>{{ armada_modal_data.color }}</h6>
                  </div>

                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Model</label
                    >
                    <h6>
                      {{
                        armada_modal_data.model ? armada_modal_data.model : "-"
                      }}
                    </h6>
                  </div>

                  <div class="col-md-12">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Masa Berlaku</label
                    >
                    <h6>
                      {{
                        armada_modal_data.stnk_expired_at
                          | moment("DD MMM YYYY")
                      }}
                    </h6>
                  </div>

                  <div
                    class="col-md-4 mt-2"
                    style="
                      border: 2px solid #333333;
                      border-radius: 2px;
                      margin-left: 12px;
                    "
                  >
                    <h6 class="text-center">STNK</h6>
                    <div class="col-md-12 text-center manual-link">
                      <a @click="show_modal_armada(1)">Lihat</a>
                    </div>
                  </div>
                </div>
              </div>

              <div
                class="col-md-4"
                style="border-right: 2px solid #333; border-radius: 2px"
              >
                <div class="row">
                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >No</label
                    >
                    <h6>{{ armada_modal_data.kir_number }}</h6>
                  </div>

                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Masa Berlaku</label
                    >
                    <h6>
                      {{
                        armada_modal_data.kir_expired_at | moment("DD MMM YYYY")
                      }}
                    </h6>
                  </div>

                  <div class="col-md-4">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Panjang</label
                    >
                    <h6>{{ armada_modal_data.length }} m</h6>
                  </div>

                  <div class="col-md-4">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Lebar</label
                    >
                    <h6>{{ armada_modal_data.width }} m</h6>
                  </div>

                  <div class="col-md-4">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Tinggi</label
                    >
                    <h6>{{ armada_modal_data.height }} m</h6>
                  </div>

                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Volume Box</label
                    >
                    <h6>{{ armada_modal_data.volume }} Cbm</h6>
                  </div>

                  <div class="col-md-6">
                    <label class="mb-0 mt-0 form-label font-weight-light"
                      >Daya Angkut</label
                    >
                    <h6>{{ armada_modal_data.tonase }} Kg</h6>
                  </div>

                  <div
                    class="col-md-4 mt-2 ml-2"
                    style="border: 2px solid #333333; border-radius: 2px"
                  >
                    <h6 class="text-center">KIR</h6>
                    <div class="col-md-12 text-center manual-link">
                      <a @click="show_modal_armada(2)">Lihat</a>
                    </div>
                  </div>
                </div>
              </div>

              <div class="col-md-4">
                <div class="row">
                  <div
                    class="col-md-5 ml-2 mb-2 p-3"
                    v-for="(i, index) in armada_modal_data.photos"
                    :key="index"
                  >
                    <embed :src="i.path" alt="" height="65" width="65" />
                  </div>
                </div>
              </div>
              <div class="col-md-12 mt-4">
                <b-button variant="light" @click="hideModal"> Tutup </b-button>
                <b-button
                  class="ml-3"
                  variant="primary"
                  @click="changeStatusArmada('aktif', armada_modal_data.id)"
                  >Setujui</b-button
                >
                <b-button
                  class="ml-3"
                  variant="outline-danger"
                  @click="changeStatusArmada('ditolak', armada_modal_data.id)"
                  >Tolak</b-button
                >

                <b-button
                  class="ml-3"
                  variant="primary"
                  @click="changeStatusArmada('aktif', armada_modal_data.id)"
                  >Aktifkan</b-button
                >
                <!-- <b-button class="ml-3" variant="warning">Hapus</b-button> -->
              </div>
            </div>
          </div>
          <template #modal-footer>
            <div class="w-100">
              <b-button variant="light" class="float-right" @click="hideModal">
                Tutup
              </b-button>
            </div>
          </template>
        </b-modal>

        <b-modal
          id="modal-center"
          centered
          ref="show-modal"
          size="lg"
          min-
          :title="title_modal"
          title-class="font-18"
          hide-footer
        >
          <div class="modal-body min-height-modal">
            <!-- <embed
              :src="
                file_path_modal != null
                  ? file_path_modal
                  : 'https://via.placeholder.com/450?text=File+Not+Found'
              "
              class="embed-classes"
            /> -->
            <PDFViewer :file="file_path_modal" />
          </div>
        </b-modal>

        <b-modal
          id="modal-center"
          centered
          ref="show-modal_armada"
          size="lg"
          min-
          :title="title_modal_armada"
          title-class="font-18"
          hide-footer
        >
          <div class="modal-body min-height-modal">
            <!-- <embed
              :src="
                file_path_modal_armada != null
                  ? file_path_modal_armada
                  : 'https://via.placeholder.com/450?text=File+Not+Found'
              "
              class="embed-classes"
            /> -->

            <PDFViewer :file="file_path_modal_armada" />
          </div>
        </b-modal>

        <!-- // Reject Reason Account  -->
        <b-modal
          id="modal-center"
          centered
          ref="show-modal-reject"
          size="lg"
          title="Account Status"
          title-class="font-18"
          hide-footer
        >
          <div class="modal-body">
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form
                @submit.prevent="handleSubmit(changeReject)"
                class="form-horizontal"
              >
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="Alasan Tolak"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Alasan Tolak"
                        label-for="input"
                      >
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="4"
                          v-model="reject_reason"
                        ></textarea>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <div class="col-sm-3">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Tolak</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </b-modal>

        <!-- // Reject Reason Driver  -->
        <b-modal
          id="modal-center"
          centered
          ref="show-modal-reject-driver"
          size="lg"
          title="Account Status Driver"
          title-class="font-18"
          hide-footer
        >
          <div class="modal-body">
            <ValidationObserver v-slot="{ handleSubmit }">
              <b-form
                @submit.prevent="handleSubmit(changeRejectDriver)"
                class="form-horizontal"
              >
                <div class="row">
                  <div class="col-md-12">
                    <ValidationProvider
                      name="Alasan Tolak"
                      rules="required"
                      v-slot="{ errors }"
                    >
                      <b-form-group
                        id="input-group-2"
                        label="Alasan Tolak"
                        label-for="input"
                      >
                        <textarea
                          class="form-control"
                          id="exampleFormControlTextarea1"
                          rows="4"
                          v-model="reject_reason_driver"
                        ></textarea>
                        <p class="fs-6 text-danger">
                          {{ errors[0] }}
                        </p>
                      </b-form-group>
                    </ValidationProvider>
                  </div>
                  <div class="col-md-6">
                    <div class="form-group row">
                      <div class="col-sm-3">
                        <b-button type="submit" variant="primary" class="w-100"
                          >Tolak</b-button
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </b-form>
            </ValidationObserver>
          </div>
        </b-modal>
      </div>
    </div>
  </Layout>
</template>
